@font-face {
  font-family: "Roboto Mono";
  font-weight: 100;
  src: url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;300;400;600;700&display=swap");
}

* {
  font-family: "Roboto Mono", monospace;
  font-weight: 100;
}

body {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  background: linear-gradient(to right, rgb(83, 105, 118), rgb(41, 46, 73));
}
